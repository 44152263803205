import { css } from '@emotion/react';
import colors from '../utils/colors';

// Pure CSS expanding underlines from:
// https://www.cssscript.com/pure-css-expanding-link-underlines/
export const navBasic = css`
  * {
    box-sizing: border-box;
  }
`;
export const exBarNav = css`
  list-style: none;
  padding: 0;
`;

export const exBarItem = css`
  opacity: 0.98;
  position: relative;
  display: inline-block;
  height: 45px;
  margin-right: 15px;
  & :hover {
    transition: 0.5s ease;
    opacity: 1;
  }
`;
export const exBarItemSmall = css`
    opacity: 0.98;
    position: relative;
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    & :hover {
        transition: 0.5s ease;
        opacity: 1;
    }
`;

export const exBarContent = css`
  opacity: 1;
  padding: 10px 5px 0;
`;

export const exBarContentSmall = css`
    opacity: 1;
    padding: 4px 2px 0;
`;

export const exBarLink = css`
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  padding: 40px 50% 0;
  transition: 0.5s ease;
  &:hover {
    padding: 40px 0 0;
  }
`;
export const exBarLinkSmall = css`
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    display: block;
    padding: 19px 50% 0;
    transition: 0.5s ease;
    &:hover {
        padding: 19px 0 0;
    }
`;

export const exBarBar = css`
  opacity: 1;
  display: block;
  height: 2px;
  background: ${colors.tertiary};
  border-radius: 1px;
`;

export const barHoverDark = css`
  background: ${colors.tertiary};
`;
