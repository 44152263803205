import styledSystem from '@emotion/styled';

import { space, width, fontSize, color, textAlign, justifyContent, borderColor, border, fontStyle, verticalAlign, height, style } from 'styled-system';

const wrap = style({
  prop: 'wrap',
  cssProperty: 'flexWrap'
});



export const Box = styledSystem.div`
  overflow: hidden;
  ${space} ${width} ${height} ${fontSize} ${color} ${textAlign} ${borderColor} ${fontStyle} ${verticalAlign};
`;

export const BoxInline = styledSystem.div`
  overflow: hidden;
  display: inline;
  ${space} ${width} ${height} ${fontSize} ${color} ${textAlign} ${borderColor} ${fontStyle} ${verticalAlign} ;
`;
export const BoxInlineBlock = styledSystem.div`
  overflow: hidden;
  display: inline-block;
  ${space} ${width} ${height} ${fontSize} ${color} ${textAlign} ${borderColor} ${fontStyle} ${verticalAlign} ;
`;
export const BoxVisible = styledSystem.div`
  overflow: visible;
  ${space} ${width} ${height} ${fontSize} ${color} ${textAlign} ${borderColor} ${fontStyle} ${verticalAlign} ;
`;

export const Flex = styledSystem.div`
  display: flex;
  ${space} ${justifyContent} ${wrap};
`;

export const Container = styledSystem.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${width}
  ${height} 
  ${verticalAlign}
`;
