import React, { Component } from 'react';
import styledSystem from '@emotion/styled';
import { navigate } from 'gatsby';
import { withTheme, ThemeProvider, css, Global } from '@emotion/react';
import { fontSize } from 'styled-system';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookie from 'universal-cookie';
import { Helmet } from 'react-helmet';
import Script from 'react-load-script';
import SimpleReactLightbox from 'simple-react-lightbox';
import toastFunc from '../utils/toast';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import colors from '../utils/colors';
import LayoutWithAuth from '../components/layoutWithAuth';
import '../style/body.css';
import '../style/snipcartCustom.css';
import '../style/toastCustom.css';
import { AuthUserContext } from '../components/Session';

// global style example from https://stackoverflow.com/questions/51637950/enable-global-theming-with-emotion
// for gatsby v1 -> v2, used strategy from https://github.com/gatsbyjs/gatsby/issues/7505

const makeGlobalStyles = (theme) => css`
  * {
    font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, roboto, noto, 'segoe ui', arial, sans-serif;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    cursor: pointer;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.secondary};
  }
  p {
    color: ${colors.accent};
  }
  body {
    background: ${theme.bg};
  }
`;

const toastCartStyle = css`
  color: black;
`;

const GlobalStyles = withTheme(({ theme }) => <Global styles={makeGlobalStyles(theme)} />);

const FontConfig = styledSystem.div`
  ${fontSize};
`;

const cookie = new Cookie();

const debug = false;

class Layout extends Component {
  constructor(props) {
    super(props);
    const hashCheck = props.location && props.location.hash && props.location.hash.length > 1 && props.location.hash;
    const bannerCookieTimeout = (process.env.GATSBY_DEVELOPMENT && process.env.GATSBY_DEVELOPMENT === 'true' && 30000) || 86400;
    if (hashCheck && hashCheck === '#showExperimental') {
      cookie.set('showExperimental', true, { path: '/', expires: new Date(Date.now() + 2592000) });
    } else if (hashCheck && hashCheck === '#showExperimentalOff') {
      cookie.set('showExperimental', false, { path: '/', expires: new Date(Date.now() + 2592000) });
    } else if (hashCheck && hashCheck === '#resetBanner') {
      cookie.set('pictureDayBanner', false, { path: '/', expires: new Date(Date.now() + 2592000) });
    } else {
      cookie.set('pictureDayBanner', true, { path: '/', expires: new Date(Date.now() + bannerCookieTimeout) });
    }
    const bannerCookieStatus = cookie.get('pictureDayBanner');
    debug && console.log('bannerCookieStatus: ', bannerCookieStatus);
    const showBanner = (bannerCookieStatus && bannerCookieStatus === 'true' && true) || false;
    const cookieValExperimental = cookie.get('showExperimental');
    debug && console.log('cookieValExperimental: ', cookieValExperimental);
    const showExperimental = (cookieValExperimental && cookieValExperimental === 'true' && true) || false;
    showExperimental && console.log('*** Experimental features enabled ***');
    this.state = {
      snipcartInitialized: false,
      // snipcartLoaded: false,
      navDarkActive: false,
      showCart: false,
      showExperimental,
      showBanner
    };
    this.toggleNavDark = this.toggleNavDark.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.handleScriptCreate = this.handleScriptCreate.bind(this);
    this.handleScriptError = this.handleScriptError.bind(this);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    /* note - hashCheck and state setting for cookie were in componentDidMount */
    debug && console.log('layout/index - constructor');
  }

  componentDidMount() {
    debug && console.log('layout/index - componentDidMount');
  }

  // eslint-disable-next-line class-methods-use-this
  handleScriptCreate() {
    debug && console.log('handleScriptCreate event from Scriptload');
  }

  // eslint-disable-next-line class-methods-use-this
  handleScriptError(e) {
    debug && console.log('handleScriptErrorPaypal: error: ', e);
    // this.setState({ snipcartLoaded: false });
  }

  handleScriptLoad() {
    debug && console.log('handleScriptLoad - snipcartLoaded - true');
    // this.setState({ snipcartLoaded: true });
    document.addEventListener('snipcart.ready', () => {
      let cartOpenState;
      debug && console.log('snipcart is ready!');
      Snipcart.DEBUG = debug && true;
      // eslint-disable-next-line no-undef
      Snipcart.events.on('cart.created', (cartState) => {
        debug && console.log('Snipcart initially created....', cartState);
      });
      // eslint-disable-next-line no-undef
      Snipcart.events.on('snipcart.initialized', (snipcartState) => {
        const cartItems = (snipcartState.cart && snipcartState.cart.items && snipcartState.cart.items.items && snipcartState.cart.items.items.length) || 0;
        debug && console.log('Snipcart initialized....items in cart:', cartItems);
        if (!cartItems) {
          this.setState({ showCart: false });
        } else {
          this.setState({ showCart: true });
        }
        this.setState({ snipcartInitialized: true });
        // this may not necessarily be true - the cart can be open upon initialization if the user is refreshing their browser while the cart is open, or if they come back to the site later after leaving the cart open.
        cartOpenState = false;
      });
      // You can safely use window.Snipcart here
      // eslint-disable-next-line no-undef
      Snipcart.events.on('item.adding', (parsedCartItem) => {
        const itemId = parsedCartItem.id;
        debug && console.log('Snipcart adding: ', itemId);
        toast.warn(`${parsedCartItem.name} is being added to cart...`, {
          className: 'snipcart-checkout', // opens snipcart if they click the toast.
          onClick: function clicked() {
            // console.log('toast is clicked!');
          },
          toastId: itemId,
          closeButton: false,
          draggable: false,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: false,
          disableAutoClose: true
        });
      });
      // eslint-disable-next-line no-undef
      Snipcart.events.on('item.added', (cartItem) => {
        debug && console.log('Snipcart added....', cartItem.id);
        this.setState({ showCart: true });
      });
      // eslint-disable-next-line no-undef
      Snipcart.events.on('item.updated', (cartItem) => {
        const itemId = cartItem.id;
        debug && console.log('Snipcart updated: ', itemId);
        // We need to ensure the cart is not opened -- we dont want to bother showing toast messages if the user is manually manipulating quantities in their cart!
        if (!cartOpenState) {
          toast.warn(`${cartItem.name} is being updated in cart...`, {
            className: 'snipcart-checkout', // opens snipcart if they click the toast.
            onClick: function clicked() {
              // console.log('toast is clicked!');
            },
            toastId: itemId,
            closeButton: false,
            draggable: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: false,
            disableAutoClose: true
          });
        }
      });
      // eslint-disable-next-line no-undef
      Snipcart.events.on('cart.confirmed', (cartConfirmResponse) => {
        debug && console.log('Snipcart confirmed....', cartConfirmResponse);
        // eslint-disable-next-line no-undef
        const snipCartState = Snipcart.store.getState();
        const cartItems = (snipCartState.cart && snipCartState.cart.items && snipCartState.cart.items.items && snipCartState.cart.items.items.length) || 0;
        if (!cartItems) {
          this.setState({ showCart: false });
        }
      });
      // eslint-disable-next-line no-undef
      Snipcart.events.on('summary.checkout_clicked', () => {
        debug && console.log('Snipcart checkout clicked');
        // eslint-disable-next-line no-undef
        const snipCartState = Snipcart.store.getState();
        const cartItems = (snipCartState.cart && snipCartState.cart.items && snipCartState.cart.items.items && snipCartState.cart.items.items.length) || 0;
        if (!cartItems) {
          this.setState({ showCart: false });
        }
      });

      // eslint-disable-next-line no-undef
      Snipcart.events.on('theme.routechanged', (routesChange) => {
        if (routesChange.from === '/' && routesChange.to !== '/') {
          debug && console.log('cart opened');
          cartOpenState = true;
        }

        if (routesChange.from !== '/' && routesChange.to === '/') {
          debug && console.log('cart closed');
          cartOpenState = false;
          // eslint-disable-next-line no-undef
          const snipCartState = Snipcart.store.getState();
          const cartItems = (snipCartState.cart && snipCartState.cart.items && snipCartState.cart.items.items && snipCartState.cart.items.items.length) || 0;
          if (!cartItems) {
            this.setState({ showCart: false });
          }
        }
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  addToCart(e) {
    // console.log('addToCart clicked! event: ', e);
    e.preventDefault();
    // e.stopPropagation(); // had to remove this, wasnt allowing snipcart click processing... but clicking 'add to cart' opens the page that houses the target item AND adds it to the cart...
    // now moving this to snipcart event hooks!
    /*
    e.preventDefault();
    toast.warn(`${shopItemName} has been added to cart.`, {
      className: 'snipcart-checkout', // opens snipcart if they click the toast.
      onClick: function clicked() {
        // console.log('toast is clicked!');
      },
      draggable: false,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      position: 'top-right',
      autoClose: 2500,
      closeOnClick: false,
      disableAutoClose: false
    }); */
  }

  toggleNavDark(inputObj) {
    const { props } = this;
    const { state } = this;
    // if we receive an object, that means we have been explicitely called by the first render of a component. set the state we are called for by that component.
    if (typeof inputObj !== 'object' || typeof inputObj === 'undefined' || inputObj === undefined) {
      // we are NOT being called by an explicit component render, but a scroll toggle.
      // console.log('PageWrapper ************ toggleNavDark called by visibility event -- inputObj: '+inputObj+' | past state: '+this.state.navDarkActive+' -- doing NOTHING');
      if (inputObj) {
        // the portion of the visibility check is visible - do not show the nav bar
        this.setState({ navDarkActive: false });
      } else {
        // we dont have the visibility check DOM in the screen - switch to dark navbar
        this.setState({ navDarkActive: true });
      }
    } else {
      // process the object and set the state accordingly.
      const hashCheck = props.location && props.location.hash && props.location.hash.length > 1 && props.location.hash;
      const { navBarDark } = inputObj;
      if (hashCheck === '#contact') {
        // automatically set the navBar to dark if we are rendering a hash. this means we are likely scrolled down the page. when this happens, the visibility check for whether or not we should automatically make the navbar light or dark will NOT be triggered, and we'll end up scrolled down with a light, transparent navbar over page content.
        this.setState({ navDarkActive: true });
      } else if (navBarDark && state.navDarkActive) {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- states EQUAL -- no change navDarkActive!');
      } else if (!navBarDark && !state.navDarkActive) {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- states EQUAL -- no change navDarkActive!');
      } else if (navBarDark && !state.navDarkActive) {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- setting navDarkActive to TRUE');
        this.setState({ navDarkActive: true });
      } else if (!navBarDark && state.navDarkActive) {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- setting navDarkActive to FALSE');
        this.setState({ navDarkActive: false });
      } else {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- doing NOTHING');
      }
    }
  }

  render() {
    const { props } = this;
    const { toggleNavDark } = this;
    const { addToCart } = this;
    const { state } = this;
    const { navDarkActive } = state;
    const { showCart } = state;
    const { snipcartInitialized } = state;
    const { showExperimental } = state;
    const { data } = props;
    const { children } = props;
    const { showBanner } = state;
    debug && console.log('layout/index - rendered');
    const { location } = props;
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, { toggleNavDark, addToCart, showCart, snipcartInitialized, navDarkActive, location, showExperimental })
    );
    const bannerEnabled = false;
    !showBanner &&
      bannerEnabled &&
      toastFunc(`Individual Picture day is November 16th and 18th! Click here for details. `, {
        className: 'toastcustom', // opens snipcart if they click the toast.
        onClick: function clicked() {
          navigate('/news/other/individualpictures2021');
        },
        closeButton: false,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        position: 'top-center',
        autoClose: 10000,
        closeOnClick: false,
        disableAutoClose: true
      });
    return (
      <FontConfig fontSize={[2, 3, 4]}>
        <div hidden id="snipcart" data-api-key={process.env.GATSBY_SNIPCARTAPIKEY} data-config-add-product-behavior="none" />
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>Florida Bulldogs</title>
          <link rel="preconnect" href="https://cdn.snipcart.com" />
          <link rel="stylesheet" href="https://cdn.snipcart.com/themes/v3.2.1/default/snipcart.css" />
          {/* We had issues with Safari loading Snipcart properly - it seemed to be trying to load twice. On its load, it removes the data-api-key attribute. It would then try to load again and that attribute would be gone -- and it wouldnt work because it couldnt find the API key. */}
          {/* We use another method to load snipcart - see the <Script> tag below... */}
          {/* <script async src="https://cdn.snipcart.com/themes/v3.0.22/default/snipcart.js" /> */}
        </Helmet>
        <Script url="https://cdn.snipcart.com/themes/v3.2.1/default/snipcart.js" onCreate={this.handleScriptCreate} onError={this.handleScriptError} onLoad={this.handleScriptLoad} />
        <GlobalStyles />
        <LayoutWithAuth>
          <SimpleReactLightbox>
            <AuthUserContext.Consumer>
              {(authUser) => {
                return (
                  <>
                    <Navigation
                      authUser={authUser}
                      data={data}
                      addToCart={addToCart}
                      showCart={showCart}
                      toggleNavDark={toggleNavDark}
                      navDarkActive={navDarkActive}
                      showExperimental={showExperimental}
                    />
                    <ToastContainer css={toastCartStyle} />
                    {childrenWithProps}
                  </>
                );
              }}
            </AuthUserContext.Consumer>
          </SimpleReactLightbox>
          <Footer />
        </LayoutWithAuth>
      </FontConfig>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.objectOf(PropTypes.any), // eslint-disable-line react/require-default-props
  data: PropTypes.objectOf(PropTypes.any) // eslint-disable-line react/require-default-props
};

export default Layout;
