import { css } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import SubMenuMobile from './SubMenuMobile';
import { Box, BoxInlineBlock, Flex, BoxVisible, Container, BoxInline } from '../../../style/basicStyle';

const displayHidden = css`
  display: none;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  left: -99999px;
`;

const w48 = css`
  width: 12rem;
`;


const w80 = css`
  width: 20rem;
`;

const nowrap=css`white-space:nowrap`;

const wFull = css`
  width: 100%;
`;

const moveRight = css`
  text-align: right;
`;

const dropdownMenu = css`
  display: block !important;
`;

const menuMobile = css`
  text-align: right !important;
`;

// eslint-disable-next-line react/prop-types
const MenuMobile = ({ linkName, isVisible, childLinks, childLinkStyling, toggleNav, navDarkActive, authUser, requireAuth, hidden, requireAdmin, requireTournaments, requireOrders, requireEvents, hideWithAuth }) => {
  return (
    <BoxInlineBlock my={[0]} css={[!isVisible && displayHidden, hidden && displayHidden, w80, nowrap, menuMobile]}>
      <Box css={[dropdownMenu, wFull]}>
        <SubMenuMobile
          linkName={linkName}
          hidden={hidden}
          hideWithAuth={hideWithAuth}
          toggleNav={toggleNav}
          authUser={authUser}
          requireAuth={requireAuth}
          requireAdmin={requireAdmin}
          requireEvents={requireEvents}
          requireTournaments={requireTournaments}
          requireOrders={requireOrders}
          requireTuition={requireOrders}
          navDarkActive={navDarkActive}
          childLinks={childLinks}
        />
      </Box>
    </BoxInlineBlock>
  );
};
export default MenuMobile;

MenuMobile.propTypes = {
  isVisible: PropTypes.bool,
  toggleNav: PropTypes.func.isRequired,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string,
      hidden: PropTypes.bool
    })
  ).isRequired
};

MenuMobile.defaultProps = {
  isVisible: false,
};
