import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Box, Flex } from '../../style/basicStyle';
import { puffInAnimation, subtitleFadeIn, slideInBack, slideInLeft, slideInRight, slideInTop, slideOutRight } from '../../style/animations';
import { disableBrowserStyling } from '../../style/genericStyling';
import colors from '../../utils/colors';

const closeButton = css`
  cursor: pointer;
  margin-right: 17px;
  margin-top: -4px;
`;

// Styles for the overlay which pops up, when the menu is clicked
const mobileStyle = css`
  position: fixed;
  background-color: ${colors.secondary};
  color: ${colors.primary};
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  & ul {
    list-style-type: none;
    height: 100%;
    text-align: center;
    & div {
      // text-align: center;
    }
  }
`;

class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.mobileContainer = document.createElement('div');
    document.body.appendChild(this.mobileContainer);
  }

  componentWillUnmount() {
    document.body.removeChild(this.mobileContainer);
  }

  render() {
    const { props } = this;
    return ReactDOM.createPortal(
      <nav css={[mobileStyle, props.mobileFadeOut ? slideOutRight : slideInRight]}>
        <Box css={[disableBrowserStyling]} onClick={() => props.toggleNav()} onKeyPress={() => props.toggleNav()} role="button" tabIndex="0">
          <Box fontSize={[5]} py={[3]} px={[2]} style={{ fontWeight: 'bold' }} css={closeButton}>X</Box>
        </Box>
        {props.children}
      </nav>,
      this.mobileContainer
    );
  }
}

MobileNav.propTypes = {
  mobileFadeOut: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default MobileNav;
