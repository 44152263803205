import { css } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import SubMenu from './SubMenu';
import { Box, Flex, BoxVisible, Container, BoxInline } from '../../../style/basicStyle';
import colors from '../../../utils/colors';

export const displayHidden = css`
  display: none;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  left: -99999px;
`;

const w48 = css`
  width: 12rem;
`;

const bgDark = css`
  background: ${colors.secondary};
`;

const bgLight = css`
  background: ${colors.primary};
  opacity: 0.85;
`;

const absolute = css`
  position: absolute;
`;

const dropdownMenu = css`
  display: block !important;
  border-radius: 5px;
  width: 100%;
`;

// eslint-disable-next-line react/prop-types
const Menu = ({ linkName, isVisible, childLinks, hidden, childLinkStyling, navDarkActive, toggleNav, authUser, requireAuth, requireAdmin, requireOrders, requireTournaments, requireTuition, requireEvents, hideWithAuth }) => {
  return (
    <Box css={[!isVisible && displayHidden, hidden && displayHidden, absolute, w48, childLinkStyling]}>
      <Box mt={[1]} px={[1]} py={[1]} css={[(navDarkActive && bgDark) || bgLight, dropdownMenu]}>
        <SubMenu key={linkName} linkName={linkName} hidden={hidden} hideWithAuth={hideWithAuth} toggleNav={toggleNav} authUser={authUser} requireTuition={requireTuition} requireAuth={requireAuth} requireTournaments={requireTournaments} requireAdmin={requireAdmin}  requireEvents={requireEvents} requireOrders={requireOrders} navDarkActive={navDarkActive} childLinks={childLinks} />
      </Box>
    </Box>
  );
};
export default Menu;

Menu.propTypes = {
  isVisible: PropTypes.bool,
  navDarkActive: PropTypes.bool,
  hidden: PropTypes.bool,
  requireAuth: PropTypes.bool,
  requireAdmin: PropTypes.bool,
  requireOrders: PropTypes.bool,
  requireEvents: PropTypes.bool,
  requireTournaments: PropTypes.bool,
  requireTuition: PropTypes.bool,
  hideWithAuth: PropTypes.bool,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string
    })
  ).isRequired
};

Menu.defaultProps = {
  hidden: false,
  isVisible: false,
  navDarkActive: false,
  requireAuth: false,
  hideWithAuth: false,
  requireAdmin: false,
  requireOrders: false,
  requireEvents: false,
  requireTournaments: false,
  requireTuition: false
};
