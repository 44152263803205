import { toast } from 'react-toastify';
import md5 from 'md5';

export default function toastFunc(message, options) {
  const toastId = md5(JSON.stringify(message));
  let toastOptions;
  if (options) {
    // eslint-disable-next-line no-param-reassign
    options.toastId = toastId;
    toastOptions = options;
  } else {
    toastOptions = { toastId };
  }
  toast(message, toastOptions);
}
