// import 'whatwg-fetch'; // fix to load fetch polyfill from https://github.com/gatsbyjs/gatsby/issues/2177 -- not supposed to be needed with current gatbsy -- https://github.com/gatsbyjs/gatsby/issues/2177
import { anchorate } from 'anchorate';
import AOS from 'aos';
import 'aos/dist/aos.css';

const onClientEntry = () => {
  AOS.init({
    // Global settings
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

    // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
    offset: 0, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation
  });
};

const onRouteUpdate = () => {
  anchorate({
    scroller(element) {
      if (!element) return false;
      element.scrollIntoView({ behavior: 'smooth', alignToTop: false });
      return true;
    }
  });
};

export { onClientEntry, onRouteUpdate };
