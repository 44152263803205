const serviceAccount = {
  apiKey: process.env.FB_AUTH_API_KEY,
  authDomain: process.env.FB_AUTH_AUTH_DOMAIN,
  databaseURL: process.env.FB_AUTH_DATABASE_URL,
  projectId: process.env.FB_AUTH_PROJECT_ID,
  storageBucket: process.env.FB_AUTH_STORAGE_BUCKET,
  messagingSenderId: process.env.FB_AUTH_MESSAGING_SENDER_ID,
  url: process.env.FB_AUTH_CONFIRMATION_EMAIL_REDIRECT
};

if (!serviceAccount.apiKey) {
  console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
  console.log('firebase error - missing vars. process.env: ', process.env);
  throw new Error('firebase auth init error - missing config');
}

class Firebase {
  constructor(firebaseApp) {
    const app = firebaseApp.default;
    try {
      app.initializeApp(serviceAccount);
    } catch (e) {
      console.log('initializeApp ERROR app is: ', app);
      console.log('app.initializeApp config: ', serviceAccount);
      console.log('app.initializeApp error: ', e);
    }

    /* Helper */
    try {
      this.serverValue = app.database.ServerValue;
      this.emailAuthProvider = app.auth.EmailAuthProvider;
    } catch (e) {
      console.log('ERROR app.helper app: ', app);
      console.log('ERROR app.helper error: ', e);
    }

    /* Firebase APIs */

    try {
      this.auth = app.auth();
    } catch (e) {
      console.log('ERROR app.auth config - app: ', serviceAccount);
      console.log('app auth error: ', e);
    }

    try {
      this.db = app.database();
    } catch (e) {
      console.log('ERROR app database - app: ', app);
      console.log('app database error: ', e);
    }
    /* Social Sign In Method Provider */

    try {
      this.googleProvider = new app.auth.GoogleAuthProvider();
      this.facebookProvider = new app.auth.FacebookAuthProvider();
      this.twitterProvider = new app.auth.TwitterAuthProvider();
    } catch (e) {
      console.log('app social provider error: ', e);
    }
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doSignInWithCustomToken = (customToken) => this.auth.signInWithCustomToken(customToken);

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: `${serviceAccount.url}/?email=${this.auth.currentUser.email}`
    });

  doDisplayNameUpdate = (displayName) =>
    this.auth.currentUser.updateProfile({
      displayName
    });

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  doEmailUpdate = (email) => this.auth.currentUser.updateEmail(email);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then((snapshot) => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            // eslint-disable-next-line no-param-reassign
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = (uid) => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');
}

let firebase;

function getFirebase(app, auth, database) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database);
  }

  return firebase;
}

export default getFirebase;
