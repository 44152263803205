import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { displayHidden } from '../Menu/index';
import { BoxInline, Box, Flex } from '../../../style/basicStyle';
import MenuMobile from '../MenuMobile';
import feather from '../../../utils/feather';
import { disableBrowserStyling, displayInline, bulldogsColor1, bulldogsColor2 } from '../../../style/genericStyling';

const featherParentLink = css`
  display: inline;
  transition: all 0.5s ease-in-out;
`;

const featherLink = css`
  transition: all 0.5s ease-in-out;
`;

const expandChild = css`
  position: absolute;
  overflow: visible;
  padding-top: 4px;
  padding-left: 10px;
  transition: all 0.5s ease-in-out;
`;




// eslint-disable-next-line react/prop-types
const NavBarLinkMobile = ({ linkName, styling, childLinkStyling, link, navDarkActive, hidden, showExperimental, childLinks, toggleNav, requireAuth, requireTuition, requireTournaments, requireOrders, requireEvents, requireAdmin, authUser, hideWithAuth, linkNameSignedIn }) => {
  const [visible, setVisible] = useState(false); // todo: set back to false!

  const menuToggle = () => {
    if (childLinks && childLinks.length && childLinks.length > 0) {
      if (visible) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    } else {
      toggleNav();
    }
  };
  // eslint-disable-next-line react/prop-types
  const username = authUser && linkNameSignedIn && authUser.username && authUser.username.length && authUser.username.split(' ')[0];
  return (
    (!requireAuth || (authUser && !hideWithAuth)) && (
      <li css={[ hidden && displayHidden]}>
        <Box
          css={[disableBrowserStyling]}
          my={[2]}
          fontSize={[4]}
          p={[1]}
          style={{ fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => menuToggle()}
          role="button"
          tabIndex="-1"
          onKeyPress={() => menuToggle()}
        >
          {(childLinks && childLinks.length > 0 && (
            <Box my={[0]}>
              {/* eslint-disable-next-line react/prop-types */}
              <BoxInline>{(username && username.length && username) || linkName}</BoxInline>
              <BoxInline css={[expandChild]}>{(!visible && feather('plus', ['25', '25'], featherLink, featherParentLink)) || feather('minus', ['25', '25'], featherLink, featherParentLink)}</BoxInline>
            </Box>
          )) || <Link to={link}>{linkName}</Link>}
        </Box>
        {childLinks && childLinks.length > 0 && (
          <MenuMobile
            linkName={linkName}
            hidden={hidden}
            childLinkStyling={childLinkStyling}
            linkNameSignedIn={linkNameSignedIn}
            hideWithAuth={hideWithAuth}
            requireAdmin={requireAdmin}
            requireOrders={requireOrders}
            requireTournaments={requireTournaments}
            requireTuition={requireTuition}
            requireEvents={requireEvents}
            requireAuth={requireAuth}
            authUser={authUser}
            navDarkActive={navDarkActive}
            toggleNav={toggleNav}
            childLinks={childLinks}
            isVisible={visible}
          />
        )}
      </li>
    )
  );
};

export default NavBarLinkMobile;

NavBarLinkMobile.propTypes = {
  toggleNav: PropTypes.func.isRequired,
  linkName: PropTypes.string.isRequired,
  link: PropTypes.string,
  showExperimental: PropTypes.bool,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string
    })
  )
};

NavBarLinkMobile.defaultProps = {
  link: '/',
  showExperimental: false,
  childLinks: []
};
