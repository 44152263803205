module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-177346158-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Florida Bulldogs Hockey","short_name":"Florida Bulldogs","start_url":"/?homescreen=1","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"./src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e234578807767df2f050a2312589522e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"bio","store":true},{"name":"teamMemberName","store":true},{"name":"jerseyNumber","store":true},{"name":"isNonPlayer","store":true},{"name":"shopItemName","store":true},{"name":"hideIndex","store":true},{"name":"outOfStock","store":true},{"name":"registrationRequired","store":true},{"name":"slug","store":true},{"name":"shopId","store":true},{"name":"customField1","store":true},{"name":"customField2","store":true},{"name":"customField3","store":true},{"name":"customField4","store":true},{"name":"customField5","store":true},{"name":"customField6","store":true},{"name":"customField7","store":true},{"name":"customField8","store":true},{"name":"customField9","store":true},{"name":"customField10","store":true},{"name":"checkbox1","store":true},{"name":"checkbox2","store":true},{"name":"shopItemColors","store":true},{"name":"price","store":true},{"name":"title","store":true},{"name":"date","store":true},{"name":"content","store":false},{"name":"relativeDirectory","store":true},{"name":"excerpt","store":true},{"name":"teamType","store":true},{"name":"shopType","store":true},{"name":"lastName","store":true},{"name":"author","store":true},{"name":"targeturl","store":true},{"name":"targeturlname","store":true},{"name":"targeturltitle","store":true},{"name":"articleCategory","store":true}],"resolvers":{"TeamYaml":{},"ShopYaml":{},"MarkdownRemark":{}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
