/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line */
import React from 'react';
import { navigate } from 'gatsby';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    _initFirebase = false;

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    componentWillUnmount() {
      this.listener && this.listener();
    }

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;

        this.listener = this.props.firebase.onAuthUserListener(
          (authUser) => {
            this.props.firebase.authUser = authUser;
            if (!condition(authUser)) {
              navigate(ROUTES.SIGN_IN);
            }
          },
          () => navigate(ROUTES.SIGN_IN)
        );
      }
    };

    render() {
      return <AuthUserContext.Consumer>{(authUser) => (condition(authUser) ? <Component {...this.props} /> : null)}</AuthUserContext.Consumer>;
    }
  }

  return withFirebase(WithAuthorization);
};

export default withAuthorization;
