import React, { useState } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { displayHidden } from '../Menu/index';
import { Box, BoxVisible } from '../../../style/basicStyle';
import { barHoverDark, exBarBar, exBarContent, exBarItem, exBarLink, navBasic } from '../../../style/mouseOverUnderline';
import Menu from '../Menu';

const displayNone = css`
  display: none !important;
`;

const removeMarginLeft = css`margin-left:0 !important`;


// eslint-disable-next-line react/prop-types
const NavBarLink = ({ linkName, styling, childLinkStyling, link, navDarkActive, showExperimental, hidden, childLinks, toggleNav, requireAuth, requireTuition, requireAdmin, requireOrders, requireTournaments, requireEvents, authUser, hideWithAuth, linkNameSignedIn }) => {
  const [visible, setVisible] = useState(false); // todo: set back to false!

  const displayMenu = () => {
    setVisible(true);
  };
  const hideMenu = () => {
    setVisible(false);
  };
  // eslint-disable-next-line react/prop-types
  const username = authUser && linkNameSignedIn && authUser.username && authUser.username.length && authUser.username.split(' ')[0];
  return (
    (!requireAuth || (authUser && !hideWithAuth)) && (
      <li css={[ hidden && displayHidden,hidden && removeMarginLeft, exBarItem]} onMouseLeave={hideMenu}>
        <Box css={exBarContent}>{(username && username.length && linkNameSignedIn && username) || linkName}</Box>
        <Link onClick={(e) => childLinks && childLinks.length > 0 && e.preventDefault()} to={link} css={!styling && exBarLink || [styling, exBarLink]} onMouseOver={displayMenu} onFocus={displayMenu}>
          <span css={[childLinks && childLinks.length > 0 && displayNone, navBasic, exBarBar, navDarkActive && barHoverDark]} />
        </Link>
        {childLinks && childLinks.length > 0 && (
          <Menu
            linkName={linkName}
            hidden={hidden}
            childLinkStyling={childLinkStyling}
            linkNameSignedIn={linkNameSignedIn}
            hideWithAuth={hideWithAuth}
            requireAdmin={requireAdmin}
            requireOrders={requireOrders}
            requireTuition={requireTuition}
            requireEvents={requireEvents}
            requireTournaments={requireTournaments}
            requireAuth={requireAuth}
            authUser={authUser}
            navDarkActive={navDarkActive}
            toggleNav={toggleNav}
            childLinks={childLinks}
            isVisible={visible}
          />
        )}
      </li>
    )
  );
};

export default NavBarLink;

NavBarLink.propTypes = {
  linkName: PropTypes.string.isRequired,
  link: PropTypes.string,
  navDarkActive: PropTypes.bool,
  toggleNav: PropTypes.func.isRequired,
  showExperimental: PropTypes.bool,
  requireAuth: PropTypes.bool,
  requireAdmin: PropTypes.bool,
  requireOrders: PropTypes.bool,
  requireTournaments: PropTypes.bool,
  requireTuition: PropTypes.bool,
  requireEvents: PropTypes.bool,
  hideWithAuth: PropTypes.bool,
  hidden: PropTypes.bool,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string,
      hidden: PropTypes.bool
    })
  )
};

NavBarLink.defaultProps = {
  link: '/',
  navDarkActive: false,
  showExperimental: false,
  childLinks: [],
  requireAuth: false,
  hideWithAuth: false,
  requireAdmin: false,
  requireOrders: false,
  requireTournaments: false,
  requireEvents: false,
  requireTuition: false,
  hidden: false
};
