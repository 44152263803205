export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const ORDERS = '/ordersAdmin';
export const ORDERS_DETAILS = '/ordersAdmin/:id';
export const EVENTS = '/teamEventsAdmin';
export const EVENTS_DETAILS = '/eventsAdmin/:id';
export const TOURNAMENTS = '/tournamentsAdmin';
export const CLINICS = '/teamClinicsAdmin';
export const TRYOUTS = '/teamTryoutsAdmin';
export const TUITION = '/teamTuitionAdmin';
