import { css } from '@emotion/react';
import media from '../utils/media';
import colors from '../utils/colors';

export const width100 = css`
  width: 100%;
`;

export const underlineCSS = css`
  text-decoration: underline;
`;

export const inheritFonts = css`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-style: inherit;
`;
export const inheritWidth = css`
  width: inherit;
`;
export const noWrap = css`
  white-space: nowrap;
`;

export const hideSmall = css`
  display: none;
  ${media.mid`
    display:flex;
  `};
`;

export const hideSmallBox = css`
  display: none;
  ${media.mid`
    display:inline-block;
  `};
`;

export const showSmall = css`
  display: flex;
  ${media.mid`
    display: none;
  `};
`;

export const visuallyHidden = css`
  display: none;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  left: -99999px;
`;

export const visuallyHiddenMobileOnly = css`
  ${media.small`   
    display: none;
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    left: -99999px;
  `};
`;

export const ulStyle = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
  & li {
    margin: 0;
  }
`;

export const disableBrowserStyling = css`
  outline: 0 !important;
  box-shadow: none !important;
  outline-style: none !important;
  button:focus {
    outline: 0 !important;
    box-shadow: none !important;
    outline-style: none !important;
  }
`;

export const displayInline = css`
  display: inline !important;
`;

export const whiteTextShadow = css`
  color: white;
  text-shadow: 2px 2px 4px #000000;
`;

export const bulldogsColor1 = css`
  color: #13283b;
`;

export const bulldogsColor2 = css`
  color: #e7af14;
`;
