import { css } from '@emotion/react';

export const pages = [];

const linkStyleIndented = css`
  margin-left: 1.25rem !important;
`;

pages.push({
  linkName: 'Roster',
  link: '/team',
  childLinks: [
    {
      linkName: 'Managers',
      link: '/team/managers'
    },
    {
      linkName: 'Staff',
      link: '/team/staff'
    },
    {
      categoryName: 'Divisions'
    },
    {
      linkName: '6U',
      link: '/team/6u',
      linkStyle: linkStyleIndented,
      hidden: true
    },
    {
      linkName: '8U',
      link: '/team/8u',
      linkStyle: linkStyleIndented,
      hidden: true
    },
    {
      linkName: '10U',
      link: '/team/10u',
      linkStyle: linkStyleIndented
    },
    {
      linkName: '12U',
      link: '/team/12u',
      linkStyle: linkStyleIndented
    },
    {
      linkName: '14U',
      link: '/team/14u',
      linkStyle: linkStyleIndented
    },
    {
      linkName: '16U',
      link: '/team/16u',
      linkStyle: linkStyleIndented
    }
  ]
});

pages.push({
  linkName: 'Shop',
  link: '/shop',
  hidden: false,
  childLinks: [
    {
      linkName: 'Pop-Up Store',
      link: '/popupStore',
      hidden: true
    },
    {
      linkName: 'Apparel',
      link: '/shop/apparel',
      hidden: false
    },
    {
      linkName: 'Uniforms',
      link: '/shop/uniforms',
      hidden: false
    },
    {
      linkName: 'Uniforms (8U)',
      link: '/shop/uniforms-8u-only',
      hidden: false
    }
  ],
  childLinksDisabled: [
    {
      linkName: 'Clothing',
      link: '/shop/clothing',
      hidden: true
    },
    {
      linkName: 'Uniforms',
      link: '/shop/uniforms'
    }
  ]
});

const resources = {
  linkName: 'Resources',
  childLinkStyling: css`
      left: -50px;
      width: 18rem;
    `,
}

const resourcesChildLinks = [
  {
    categoryName: `Tuition/Practice`,
    hidden: false
  },
  {
    linkName: `'24 High School Stampede`,
    link: '/yetis/tuition/2024hsstampede',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: `Yetis Tuition`,
    link: '/yetis/tuition/2024yeti',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: `Yetis Tuition (Practice Only)`,
    link: '/yetis/tuition/2024yetipracticeonly',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '8U - Mites Major',
    link: '/tuition2022/tuition/8U1',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '8U - Mites Minor',
    link: '/tuition2022/tuition/8U2',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '10U - Squirt',
    link: '/tuition2022/tuition/10U',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '10U - Squirt (PRACTICE)',
    link: '/tuition2022/practice/10U',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '12U - Pee-Wee',
    link: '/tuition2022/tuition/12U',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '12U - Pee-Wee (PRACTICE)',
    link: '/tuition2022/practice/12U',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '12UA2 - Pee-Wee',
    link: '/tuition2022/tuition/12UA2',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '14U - Bantam',
    link: '/tuition2022/tuition/14U',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '16U - Midget',
    link: '/tuition2022/tuition/16U',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '10U - Squirt',
    link: '/tuition2023/tuition/202310U',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '10U - Squirt (PRACTICE)',
    link: '/tuition2023/practice/202310UP',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '12UA1 - Pee-Wee',
    link: '/tuition2023/tuition/202312UA1',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '12UA2 - Pee-Wee',
    link: '/tuition2023/tuition/202312UA2',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '12U - Pee-Wee (PRACTICE)',
    link: '/tuition2023/practice/202312UP',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '14U - Bantam',
    link: '/tuition2023/tuition/202314U',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '10U - Squirt',
    link: '/tuition2024/tuition/202410UTUITION',
    linkStyle: linkStyleIndented,
    hidden: false
  },
  {
    linkName: '12U - Pee-Wee',
    link: '/tuition2024/tuition/202412UTUITION',
    linkStyle: linkStyleIndented,
    hidden: false
  },
  {
    linkName: '14U - Bantam',
    link: '/tuition2024/tuition/202414UTUITION',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '16U - Midget',
    link: '/tuition2024/tuition/202416UTUITION',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    categoryName: 'Tryouts',
    hidden: true
  },
  {
    linkName: 'Tryouts Spring 2024',
    link: '/teamTryouts/teamTryout2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Tryouts Spring 2022',
    link: '/teamTryouts/teamTryout2022',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Tryouts Spring 2023',
    link: '/teamTryouts/teamTryout2023',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Tryouts Spring 2023 14UA2',
    link: '/teamTryouts/teamTryout202314UA2',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: `Yetis Tryouts '24`,
    link: '/teamTryouts/yetisTryout2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    categoryName: 'Clinics',
    hidden: false
  },
  {
    linkName: `Studio Sessions '24`,
    link: '/teamClinics/studioSessions2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: `Studio Sessions 2 '24`,
    link: '/teamClinics/studioSessionsNum22024',
    linkStyle: linkStyleIndented,
    hidden: false
  },
  {
    linkName: 'Summer Camp 1',
    link: '/teamClinics/shawnray2024camp1',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Summer Camp 2',
    link: '/teamClinics/shawnray2024camp2',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Small Group Camp',
    link: '/teamClinics/shawnray2024smallgroupcamp',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Stick and Puck 05/18',
    link: '/teamClinics/stickandpuckmay182024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Clinic 05/18',
    link: '/teamClinics/clinicmay18202414u16u',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Clinic 05/19',
    link: '/teamClinics/clinicmay1920248u10u12u',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Scrimmage 05/18',
    link: '/teamClinics/scrimmagemay18202414u16u',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Scrimmage 05/19',
    link: '/teamClinics/scrimmagemay19202414u16u',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '10U Day Clinic March',
    link: '/teamClinics/dayclinic10u2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Day Clinic 04/06',
    link: '/teamClinics/dayclinicapril62024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Scrimmage  04/06',
    link: '/teamClinics/scrimmageapril62024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Day Clinic 04/07',
    link: '/teamClinics/dayclinicapril72024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Scrimmage 04/07',
    link: '/teamClinics/scrimmageapril72024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Day Clinic 04/27',
    link: '/teamClinics/dayclinic10uapril2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Night Clinic 04/27',
    link: '/teamClinics/clinic8u10uapril2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Scrimmage 04/27',
    link: '/teamClinics/scrimmageapril272024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Day Clinic 4/28',
    link: '/teamClinics/clinic12u14u16uapril282024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Day Clinic 5/05',
    link: '/teamClinics/clinic10umay2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '10U Evening Clinic March',
    link: '/teamClinics/clinic10u2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Spring Clinics (Tuesday)',
    link: '/teamClinics/teamClinic2023tuesday',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Spring Clinics (Wednesday)',
    link: '/teamClinics/teamClinic2023wednesday',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Summer Camp',
    link: '/teamClinics/summercamp2023',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Mid-August 2023',
    link: '/teamClinics/teamClinic2023August',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'February Clinic 2024',
    link: '/teamClinics/teamClinic2024Feb29',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Preseason Summer Camp',
    link: '/teamClinics/preseasonsummercamp2023',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    categoryName: 'Tournament Registration',
    hidden: false
  },
  {
    linkName: `10U Dec+Feb`,
    link: '/tournaments/10udecfeb2024',
    linkStyle: linkStyleIndented,
    hidden: false
  },
  {
    linkName: `Columbus '24 + MLK '25`,
    link: '/tournaments/columbusdayandmlk2024',
    linkStyle: linkStyleIndented,
    hidden: false
  },
  {
    linkName: `Yetis 10U April '24`,
    link: '/tournaments/yetis10u2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: `Yetis '24`,
    link: '/tournaments/yetis2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Jacksonville Presidents Day',
    link: '/tournaments/presidentsday2023',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Columbus+MLK',
    link: '/tournaments/columbusdaymlk2022',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Columbus Day',
    link: '/tournaments/columbusday2023',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: `December '24`,
    link: '/tournaments/decholiday2324',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: `Lake Placid '24`,
    link: '/tournaments/lakeplacid2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: `Lake Placid '25`,
    link: '/tournaments/lakeplacid2025',
    linkStyle: linkStyleIndented,
    hidden: false
  },
  {
    linkName: `State (12u) '24`,
    link: '/tournaments/state12u2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: `State (14u) '24`,
    link: '/tournaments/state14u2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'MLK Day',
    link: '/tournaments/mlk2023',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Presidents Day',
    link: '/tournaments/presidentsday2024',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Presidents Day Tournament',
    link: '/tournaments/presidentsday2022',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: '10U State Championship',
    link: '/tournaments/sahof10ugold2022',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Columbus Day',
    link: '/tournaments/columbusday2021',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Thanksgiving Weekend',
    link: '/tournaments/thanksgiving2021',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Thanksgiving (16U)',
    link: '/tournaments/thanksgiving2024',
    linkStyle: linkStyleIndented,
    hidden: false
  },
  {
    linkName: 'MLK Mid-Florida Meltdown',
    link: '/tournaments/mlk2022',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    categoryName: 'Events/Misc',
    hidden: false
  },
  {
    linkName: 'Mites Travel Deposit',
    link: '/teamEvents/mitestraveldeposit2024',
    linkStyle: linkStyleIndented,
    hidden: false
  },
  {
    linkName: 'Lightning v. Ducks (Upper)',
    link: '/teamEvents/lightningvsducks2024upper',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Lightning v. Ducks (Lower)',
    link: '/teamEvents/lightningvsducks2024lower',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Team Pictures',
    link: '/teamEvents/teampictures2324',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Mites Jamboree',
    link: '/teamEvents/mitesjamboree2023',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    linkName: 'Mites Solar Bears Game',
    link: '/teamEvents/mitessolarbears2023',
    linkStyle: linkStyleIndented,
    hidden: true
  },
  {
    categoryName: 'Team Info'
  },
  {
    linkName: 'Schedule',
    link: '/schedule/allteams/game/allopponents/allseasons/alllocations',
    linkStyle: linkStyleIndented
  },
  {
    linkName: 'Team Documents',
    link: '/teamDocuments',
    requireAuth: true,
    linkStyle: linkStyleIndented
  }
];
function addObjectAfterCondition({arr, newObject, conditionValue}) {
  // Flag to see if we have added the object
  let objectAdded = false;
  // Iterate through the array to find the matching condition
  for (let i = 0; i < arr.length; i++) {
    // Check for condition match
    if (arr[i].categoryName === conditionValue || arr[i].link === conditionValue) {
      // Insert newObject right after the current element
      arr.splice(i + 1, 0, newObject);
      objectAdded = true;
      break; // Exit the loop once the object is added
    }
  }
  // If no matching condition was found, you could handle it here
  // For example, you could push the object to the end of the array
  if (!objectAdded) {
    console.warn('Condition value not found. Object not added.');
  }
  return arr; // Return the modified array
}

// shows how we add links available for local dev work

if (process.env.NODE_ENV === 'development') {
  addObjectAfterCondition({
    arr: resourcesChildLinks,
    newObject:
    {
      linkName: '18U - DEV ONLY',
      link: '/tuition2024/tuition/202416UTUITION',
      linkStyle: linkStyleIndented,
      hidden: false
    },
      conditionValue: '/tuition2024/tuition/202416UTUITION'
  }
  );
  addObjectAfterCondition({
      arr: resourcesChildLinks,
      newObject:
        {
          linkName: 'TEST LINK Dev Only',
          link: '/schedule/allteams/game/allopponents/allseasons/alllocations',
          linkStyle: linkStyleIndented,
          hidden: false
        },
      conditionValue: 'Team Info'
    }
  )
}
resources.childLinks = resourcesChildLinks;
pages.push(resources);


pages.push({
  linkName: 'News',
  link: '/news'
});

pages.push({
  linkName: 'About',
  link: '/about',
  childLinks: [
    {
      linkName: 'About Us',
      link: '/about'
    },
    {
      categoryName: 'Media'
    },
    {
      linkName: 'USA Hockey',
      link: 'https://www.usahockey.com/',
      linkStyle: linkStyleIndented
    },
    {
      linkName: 'Sahof',
      link: 'https://www.sahofhockey.org/',
      linkStyle: linkStyleIndented
    },
    {
      linkName: 'Livebarn',
      link: 'https://livebarn.com/',
      linkStyle: linkStyleIndented
    }
  ]
});

pages.push({
  linkName: 'Log in',
  linkNameSignedIn: 'Account',
  link: '/account',
  childLinkStyling: css`
    left: -100px;
  `,
  childLinks: [
    {
      linkName: 'Sign In',
      link: '/signin',
      hideWithAuth: true,
      requireAuth: false
    },
    {
      linkName: 'Sign Up',
      link: '/signup',
      hideWithAuth: true,
      requireAuth: false
    },
    {
      linkName: 'Account',
      link: '/account',
      requireAuth: true
    },
    {
      linkName: 'Registrations',
      link: '/registrations',
      requireAuth: true,
      hidden: true
    },
    {
      linkName: 'Tuition',
      link: '/tuition',
      requireAuth: true,
      hidden: false
    },
    {
      linkName: 'Tournaments',
      link: '/tournaments',
      requireAuth: true
    },
    {
      linkName: 'Clinics',
      link: '/teamClinics',
      requireAuth: true
    },
    {
      linkName: 'Events',
      link: '/teamEvents',
      requireAuth: true
    },
    {
      linkName: 'Tryouts',
      link: '/teamTryouts',
      requireAuth: true,
      hidden: false
    },
    {
      linkName: 'Orders',
      link: '/orders',
      requireAuth: true
    },
    {
      categoryName: 'Admin',
      requireAuth: true,
      requireAdmin: true,
      requireOrders: true,
      requireEvents: true,
      requireClinics: true,
      requireTuition: true,
      requireTryouts: true,
      requireTournaments: true,
      staffLink: true
    },
    {
      linkName: 'Team Registrations',
      link: '/registrationsAdmin',
      hidden: true,
      requireAuth: true,
      requireAdmin: true,
      linkStyle: linkStyleIndented,
      staffLink: true
    },
    {
      linkName: 'Tuition',
      link: '/tuitionAdmin',
      requireAuth: true,
      requireTuition: true,
      linkStyle: linkStyleIndented,
      hidden: false,
      staffLink: true
    },
    {
      linkName: 'Tournaments',
      link: '/tournamentsAdmin',
      requireAuth: true,
      requireTournaments: true,
      linkStyle: linkStyleIndented,
      staffLink: true
    },
    {
      linkName: 'Events',
      link: '/teamEventsAdmin',
      requireAuth: true,
      requireEvents: true,
      linkStyle: linkStyleIndented,
      staffLink: true
    },
    {
      linkName: 'Tryouts',
      link: '/teamTryoutsAdmin',
      requireAuth: true,
      requireTryouts: true,
      linkStyle: linkStyleIndented,
      staffLink: true
    },
    {
      linkName: 'Clinics',
      link: '/teamClinicsAdmin',
      requireAuth: true,
      requireClinics: true,
      linkStyle: linkStyleIndented,
      staffLink: true
    },
    {
      linkName: 'Orders',
      link: '/ordersAdmin',
      requireAuth: true,
      requireOrders: true,
      linkStyle: linkStyleIndented,
      staffLink: true
    },
    {
      linkName: 'Users',
      link: '/users',
      requireAuth: true,
      requireAdmin: true,
      linkStyle: linkStyleIndented,
      staffLink: true
    },
    {
      linkName: 'Sign Out',
      link: '/signout',
      requireAuth: true
    }
  ]
});

export default pages;
