import { css } from '@emotion/react';
import colors from '../utils/colors';

export const shadedPanel = css`
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-duration: 0.3s;
`;

export const shadedPanelOld = css`
  background-color: ${colors.primary};
  box-shadow: 0 20px 25px -12px rgba(0, 0, 0, 0.09);
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-duration: 0.3s;
  border-radius: 0.25rem !important;
`;

export const shadedPanelDark = css`
  background-color: ${colors.secondary};
`;

export const thickerBorder = css`
  border 0.5px solid;
  border-color: lightgray;
`;
