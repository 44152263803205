import React from 'react';
import styledSystem from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { fontSize } from 'styled-system';
import colors from '../../utils/colors';
import { Box, Flex } from '../../style/basicStyle';
import feather from '../../utils/feather';
import media from '../../utils/media';
import { svgStyles } from '../../style/featherIconStyle';
import Wrapper from '../../style/Wrapper';
import { showSmall, hideSmall, noWrap, visuallyHidden, ulStyle } from '../../style/genericStyling';

const footerStyle = css`
  overflow: hidden;
  background-color: ${colors.accent};
  color: rgba(255, 255, 255, 0.5);
  text-align: center;

  & img {
    display: block;
    margin: 0;
  }
  & p {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const social = css`
  ${media.large`
    width: 50%;
    margin-left: auto;
  `};
`;

const socialList = css`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${social};
`;

const FontConfig = styledSystem.div`
  ${fontSize};
`;

const footerUlStyle = css`
  ${ulStyle};
  text-transform: none;
  margin-bottom: 1.45rem;
  & li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  & a {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.15s ease-in;
    transition: color 0.15s ease-in;

    &:hover {
      text-decoration: none;
      box-shadow: none;
      opacity: 1;
      transition: opacity 0.15s ease-in;
    }
  }
`;

const footerLink = css`
    cursor: pointer;
    opacity: 0.5;
    transition: color 0.15s ease-in;
    &:hover {
      text-decoration: none;
      box-shadow: none;
      opacity: 1;
      transition: opacity 0.15s ease-in;
    }
  }
`;

const Footer = props => (
  <FontConfig fontSize={[2, 2, 3]}>
    <div css={footerStyle}>
      <Box px={[3, 3, 1]} textAlign="center">
        <FontConfig fontSize={[1, 2, 2]}>
          <p>
            <Link css={footerLink} to="/">
              {`© 2020 ${props.name}`}
            </Link>
            <span style={{ opacity: 0.5 }}> · </span>
            <a href="mailto:info@floridabulldogshockey.com" css={footerLink}>
              info@floridabulldogshockey.com
            </a>
            <span style={{ opacity: 0.5 }}> · </span>
            <Link css={footerLink} to="/terms">
              Terms
            </Link>
          </p>
        </FontConfig>
      </Box>
    </div>
  </FontConfig>
);

Footer.propTypes = {
  name: PropTypes.string
};

Footer.defaultProps = {
  name: 'Florida Bulldogs'
};

export default Footer;
