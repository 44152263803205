/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { width100 } from '../../../../style/genericStyling';
import { Box, Flex, BoxVisible, Container, BoxInline } from '../../../../style/basicStyle';
import colors from '../../../../utils/colors';
import { barHoverDark, exBarBar, exBarContent, exBarContentSmall, exBarItem, exBarItemSmall, exBarLink, exBarLinkSmall, navBasic } from '../../../../style/mouseOverUnderline';
import * as ROLES from '../../../../constants/roles';


const displayHidden = css`
  display: none;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  left: -99999px;
`;

const bgDark = css`
  background: ${colors.secondary};
`;

const bgLight = css`
  background: ${colors.primary};
`;

const displayBlock = css`
  display: block !important;
`;

const subMenuStyle = css`
  text-align: left;
  float: left;
`;

const categoryStyle = css`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  font-weight: bold;
  opacity: 0.5;
  --text-opacity: 0.5;
`;
const CategoryName = (item, index) => (
  <>
    <Box mt={[1]} fontSize={[2]} css={[categoryStyle]}>
      {item.categoryName}
    </Box>
  </>
);

const categoryParentStyle = css`
  float: left;
`;

// eslint-disable-next-line react/prop-types
const SubMenu = ({ linkName, childLinks, navDarkActive, hidden, authUser, toggleNav }) => {
  const resourcesPage = linkName === 'ResourcesDISABLED';
  return (
    <Box key={`${linkName}-subMenuparent`} fontSize={[resourcesPage && 1 || 3]} style={{ justifyContent: 'left', marginLeft: 'initial' }} css={[hidden && displayHidden, displayBlock, subMenuStyle]}>
      {childLinks.map(
        (item, index) =>
          // Display conditions
          ((!item.requireAuth && !authUser && !item.requireAdmin && !item.requireOrders && !item.requireTournaments && !item.requireEvents && !item.requireTuition && !item.requireTryouts && !item.requireClinics) || // Does our page not require authorization AND is there no user AND does it not require admin/orders/tournaments/events roles?
            (authUser && !item.hideWithAuth && !item.requireAdmin && !item.requireOrders && !item.requireTournaments && !item.requireEvents && !item.requireTuition&& !item.requireTryouts && !item.requireClinics) || // Does our page require a user AND does our page need to be displayed if the user is logged in AND does it not require admin/orders/tournaments/events?
            (authUser && item.requireAuth && item.requireAdmin && authUser.roles[ROLES.ADMIN]) || // Does our page require a user AND does it need to be displayed with a logged in user AND is the user an admin?
            (authUser && item.requireAuth && item.requireTournaments && (authUser.roles[ROLES.TOURNAMENTS] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have tournament rights OR admin rights?
            (authUser && item.requireAuth && item.requireTryouts && (authUser.roles[ROLES.TRYOUTS] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have events rights OR admin rights?
            (authUser && item.requireAuth && item.requireEvents && (authUser.roles[ROLES.EVENTS] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have events rights OR admin rights?
            (authUser && item.requireAuth && item.requireTuition && (authUser.roles[ROLES.TUITION] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have tuition rights OR admin rights?
            (authUser && item.requireAuth && item.requireClinics && (authUser.roles[ROLES.CLINICS] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have clinic rights OR admin rights?
            (authUser && item.requireAuth && item.requireOrders && (authUser.roles[ROLES.ORDERS] || authUser.roles[ROLES.ADMIN]))) && // Does our page require a user AND does it need to be displayed with a logged in user AND does user have orders rights OR admin rights?
          !item.hidden && ( // can our user view orders?
            <Fragment key={`${item.linkName}parent${item.categoryName}-${index}`}>
              <Box style={{ justifyContent: 'left', marginLeft: 'initial'}} css={item.categoryName && categoryParentStyle || width100} key={`${index + item.linkName}SubMenu`}>
                {(item.categoryName && CategoryName(item, index)) || (
                  <Box style={{ justifyContent: 'left', marginLeft: 'initial' }} mt={[1]}>
                    <Box style={{ justifyContent: 'left', marginLeft: 'initial' }} key={item.linkName + index}>
                      <ul>
                        <li key={item.linkName + 'linkNameList' + index} style={{ justifyContent: 'left' }} css={[!resourcesPage && exBarItem || exBarItemSmall, item.linkStyle]}>
                          <Box style={{ justifyContent: 'left' }} css={!resourcesPage && exBarContent || exBarContentSmall}>
                            {item.linkName}
                          </Box>
                          {(item.link && item.link.indexOf('://') > -1 && (
                            // eslint-disable-next-line react/no-array-index-key
                            <a target="_blank" rel="noreferrer" style={{ justifyContent: 'left' }} href={item.link} css={!resourcesPage && exBarLink || exBarLinkSmall}>
                              <span style={{ justifyContent: 'left' }} css={[navBasic, exBarBar, navDarkActive && barHoverDark]} />
                            </a>
                          )) || (
                            <Link style={{ justifyContent: 'left' }} to={item.link} css={!resourcesPage && exBarLink || exBarLinkSmall}>
                              <span style={{ justifyContent: 'left' }} css={[navBasic, exBarBar, navDarkActive && barHoverDark]} />
                            </Link>
                          )}
                        </li>
                      </ul>
                    </Box>
                  </Box>
                )}
              </Box>
              {item.categoryName && <br />}
            </Fragment>
          )
      )}
    </Box>
  );
};

export default SubMenu;

SubMenu.propTypes = {
  linkName: PropTypes.string,
  navDarkActive: PropTypes.bool,
  hidden: PropTypes.bool,
  requireAdmin: PropTypes.bool,
  requireOrders: PropTypes.bool,
  requireTournaments: PropTypes.bool,
  requireTuition: PropTypes.bool,
  requireEvents: PropTypes.bool,
  requireClinics: PropTypes.bool,
  requireAuth: PropTypes.bool,
  hideWithAuth: PropTypes.bool,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string
    })
  ).isRequired
};

SubMenu.defaultProps = {
  hidden: false,
  navDarkActive: false,
  requireAuth: false,
  hideWithAuth: false,
  requireAdmin: false,
  requireClinics: false,
  requireOrders: false,
  requireEvents: false,
  requireTournaments: false,
  requireTuition: false
};
